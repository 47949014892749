// import(/* webpackChunkName: "about" */ "./components/Landing.vue")

import Vue from "vue";
import Router from "vue-router";
import Home from "./components/Login/Home";

import { TokenService } from "./services/storage.service";
import store from "./store/store";
import { ACTION_IS_LOGGED_IN } from "./store/constants/user";

Vue.use(Router);

function userHasPermission(requiredPermission, secondaryPermission) {
  const isTwoFAEnabled =
    store.state.user.twoFactor || TokenService.getConfig("twoFaEnabled");
  if (!isTwoFAEnabled) return false;

  const userPermissions = TokenService.getConfig("permissions");

  return (
    userPermissions.includes(requiredPermission) ||
    userPermissions.includes(secondaryPermission)
  );
}

const router = new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      }
    },
    {
      path: "/invoice",
      name: "invoice",
      component: () => import("./components/Statistics/Invoice.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("GENERATE_INVOICE") ? next() : next("/")
    },
    {
      path: "/balance-confirmation",
      name: "balance-confirmation",
      component: () =>
        import("./components/Statistics/BalanceConfirmation.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("GENERATE_BALANCE_CONFIRMATION") ? next() : next("/")
    },
    {
      path: "/create-organisation",
      name: "create-organisation",
      component: () => import("./components/Organisations/CreateOrganisation.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("MANAGE_ORGANISATIONS") ? next() : next("/")
    },
    {
      path: "/update-organisation",
      name: "update-organisation",
      component: () => import("./components/Organisations/UpdateOrganisation.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("MANAGE_ORGANISATIONS") ? next() : next("/")
    },
    {
      path: "/country-blacklist",
      name: "country-blacklist",
      component: () => import("./components/CountryBlacklist.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("MANAGE_BLACKLISTED_COUNTRIES") ? next() : next("/")
    },
    {
      path: "/banking",
      name: "banking",
      component: () => import("./components/Banking/Banking.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("FETCH_BANK_ACCOUNTS") ? next() : next("/")
    },
    {
      path: "/balances",
      name: "balances",
      component: () => import("./components/Balances/Balances.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("FETCH_BANK_BALANCE", "FETCH_CRYPTO_WALLET_BALANCE")
          ? next()
          : next("/")
    },
    {
      path: "/banking/edit-account/:id",
      name: "manage-account",
      component: () => import("./components/Banking/ApproveRejectAccount.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("MANAGE_BANK_ACCOUNTS") ? next() : next("/")
    },
    {
      path: "/banking/:id",
      name: "account-details",
      component: () => import("./components/Banking/BankAccountDetails.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("FETCH_BANK_ACCOUNTS") ? next() : next("/")
    },
    {
      path: "/unblock-transfer",
      name: "unblock-transfer",
      component: () => import("./components/UnblockTransfer.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("MANAGE_BLOCKED_CRYPTO_DEPOSITS") ? next() : next("/")
    },
    {
      path: "/illicit-deposits",
      name: "illicit-deposits",
      component: () => import("./components/IllicitDeposits"),
      beforeEnter: (to, from, next) =>
        userHasPermission("FETCH_BLOCKED_CRYPTO_DEPOSITS") ? next() : next("/")
    },
    {
      path: "/process-explorer/:service",
      name: "process-explorer",
      component: () => import("./components/ProcessExplorer/ProcessList.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("VIEW_PROCESSES") ? next() : next("/")
    },
    {
      path: "/process-explorer/:service/:id",
      name: "process-details",
      component: () => import("./components/ProcessExplorer/SingleProcess.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("VIEW_PROCESSES") ? next() : next("/")
    },
    {
      path: "/authentication",
      name: "authentication",
      component: () => import("./components/Authentication.vue")
    },
    {
      path: "/change-password",
      name: "change-password",
      component: () => import("./components/UpdatePassword.vue")
    },
    {
      path: "/users",
      name: "users",
      component: () => import("./components/Users/AllUsers.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("MANAGE_OPERATORS") ? next() : next("/")
    },
    {
      path: "/edit-user/:id",
      name: "edit-user",
      component: () => import("./components/Users/EditUser.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("MANAGE_OPERATORS") ? next() : next("/")
    },
    {
      path: "/create-user",
      name: "create-user",
      component: () => import("./components/Users/CreateUser.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("MANAGE_OPERATORS") ? next() : next("/")
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("./components/Login/ForgotPassword.vue"),
      meta: {
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      }
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("./components/Login/ResetPassword.vue"),
      meta: {
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      }
    },
    {
      path: "/transaction-ledger",
      name: "transaction-ledger",
      component: () => import("./components/TransactionLedger"),
      beforeEnter: (to, from, next) =>
        userHasPermission("GENERATE_TRANSACTION_LEDGER") ? next() : next("/")
    },
    {
      path: "/umbrella",
      name: "umbrella",
      component: () => import("./components/Umbrella/UmbrellaRewards"),
      beforeEnter: (to, from, next) =>
        userHasPermission("FETCH_UMBRELLA_REWARDS") ? next() : next("/")
    },
    {
      path: "/unallocated-deposits",
      name: "top-ups-and-unallocated-deposits",
      component: () =>
        import("./components/InternalTransfers/UnallocatedDeposits.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("CREATE_UNASSOCIATED_DEPOSIT") ? next() : next("/")
    },
    {
      path: "/case-management",
      name: "case-management",
      component: () => import("./components/CaseManagement/CaseManagement.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("FETCH_COMPLIANCE_CASES") ? next() : next("/")
    },
    {
      path: "/compliance-rules",
      name: "compliance-rules",
      component: () => import("./components/ComplianceRules/RulesList.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("FETCH_COMPLIANCE_RULES") ? next() : next("/")
    },
    {
      path: "/compliance-rules/create",
      name: "create-compliance-rule",
      component: () =>
        import("./components/ComplianceRules/CreateEditRule.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("CREATE_COMPLIANCE_RULES") ? next() : next("/")
    },
    {
      path: "/compliance-rules/edit/:id",
      name: "edit-compliance-rule",
      component: () =>
        import("./components/ComplianceRules/CreateEditRule.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("CREATE_COMPLIANCE_RULES") ? next() : next("/")
    },
    {
      path: "/manual-movements",
      name: "manual-movements",
      component: () => import("./components/InternalTransfers/ManualMovements.vue"),
      beforeEnter: (to, from, next) => userHasPermission("MANAGE_ACCOUNTING_LEDGER") ? next() : next("/")
    },
    {
      path: "/whitelabel-fiat-deposit",
      name: "whitelabel-fiat-deposit",
      component: () => import("./components/InternalTransfers/WhitelabelFiatDeposit.vue"),
      beforeEnter: (to, from, next) => userHasPermission("MANAGE_ACCOUNTING_LEDGER") ? next() : next("/")
    },
    {
      path: "/fiu-report",
      name: "fiu-report",
      component: () => import("./components/FIUReport.vue"),
      beforeEnter: (to, from, next) => userHasPermission("FETCH_COMPLIANCE_REPORTS") ? next() : next("/")
    }
  ]
});

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  );
  const loggedIn = !!TokenService.getConfig("email");
  const isTwoFAEnabled =
    store.state.user.twoFactor || TokenService.getConfig("twoFaEnabled");

  if (!isPublic && !loggedIn) {
    store.dispatch(ACTION_IS_LOGGED_IN, false);
    TokenService.removeCofnig();

    return next({
      path: "/"
    });
  }

  if (loggedIn && onlyWhenLoggedOut) {
    if (!isTwoFAEnabled) return next("/authentication");
    return next("/invoice");
  }
  if (!to.matched.length) {
    next("/");
  }

  next();
});

export default router;
